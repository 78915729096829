import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

let msalInstance = null;

async function initializeMsal() {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    
    // Check if there's a stored active account and set it
    const storedAccount = localStorage.getItem('activeAccount');
    if (storedAccount) {
      msalInstance.setActiveAccount(JSON.parse(storedAccount));
    }
  }
  return msalInstance;
}

export async function login() {
  const msalInstance = await initializeMsal();
  try {
    const loginResponse = await msalInstance.loginPopup({
      scopes: ['api://21171aa0-ad31-40d1-ab13-6fd9f3b026c3/user_impersonation'],
    });
    
    if (loginResponse.account) {
      msalInstance.setActiveAccount(loginResponse.account);
      localStorage.setItem('activeAccount', JSON.stringify(loginResponse.account));
      return true;
    }
    return false;
  } catch (error) {
    console.error('Login failed:', error);
    return false;
  }
}

export async function logout() {
  const msalInstance = await initializeMsal();
  try {
    await msalInstance.logoutPopup();
    localStorage.removeItem('activeAccount');
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
}

export async function isAuthenticated() {
  const msalInstance = await initializeMsal();
  return msalInstance.getAllAccounts().length > 0;
}

export async function getUserInfo() {
  const msalInstance = await initializeMsal();
  const account = msalInstance.getActiveAccount() || JSON.parse(localStorage.getItem('activeAccount'));
  
  if (account) {
    return {
      name: account.name || account.username || 'Ukjent navn',
      username: account.username || account.localAccountId || 'Ukjent bruker',
      userId: account.localAccountId || 'Ukjent ID'
    };
  }
  return null;
}

export async function getAccessToken() {
  const msalInstance = await initializeMsal();
  const account = msalInstance.getActiveAccount() || JSON.parse(localStorage.getItem('activeAccount'));
  
  if (!account) {
    throw new Error('No active account found.');
  }

  const tokenRequest = {
    scopes: ['api://21171aa0-ad31-40d1-ab13-6fd9f3b026c3/user_impersonation'],
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup(tokenRequest);
      return response.accessToken;
    }
    throw error;
  }
}
