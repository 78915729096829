<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <p v-if="currentLotteryId" class="text-center mb-4">Lottery ID: {{ currentLotteryId }}</p>
        <v-btn @click="startSpin" color="green" :disabled="isSpinning">Trekk vinner</v-btn>
        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-card-title class="headline">Vinneren er</v-card-title>
            <v-card-text v-if="winnerDetails">
              <p>Navn: {{ winnerDetails.name }}</p>
              <p>Email: {{ winnerDetails.email }}</p>
            </v-card-text>
            <v-card-text v-else>
              <p>Ingen vinnende lodd trukket ennå.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">Lukk</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="wheel-container">
          <div class="wheel-pointer"></div>
          <roulette-table
            ref="rouletteTable"
            :items="wheelItems"
            :size="600"
            @wheel-end="onWheelEnd"
          />
        </div>
        <div id="wheel_meta">
          <h2>Siste trekninger</h2>
          <div class="last-numbers">
            <span 
              v-for="number in lastNumbers" 
              :key="number.id" 
              class="number-field" 
              :style="{ background: number.color }"
            >
              {{ number.number }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouletteTable from './RouletteTable.vue';
import config from '@/config';
import axios from 'axios';
import { getAccessToken } from './authService';

export default {
  name: "SpinningWheel",
  components: {
    RouletteTable
  },
  data() {
    return {
      spinResult: null,
      isSpinning: false,
      lastNumbers: [],
      dialog: false,
      tickets: [],
      winnerDetails: null,
      currentLotteryId: null,
      wheelItems: [],
    };
  },
  mounted() {
    this.extractLotteryIdFromUrl();
    if (this.currentLotteryId) {
      this.fetchTicketsForLottery();
    } else {
      console.error('No valid lottery ID found in URL');
      alert('No valid lottery ID found. Please check the URL.');
    }
  },
  methods: {
    extractLotteryIdFromUrl() {
      const path = this.$route.path;
      const parts = path.split('/');
      this.currentLotteryId = parts[parts.length - 1];
    },
    async startSpin() {
      if (this.wheelItems.length > 0 && !this.isSpinning) {
        this.isSpinning = true;
        this.$refs.rouletteTable.launchWheel();
      } else if (this.wheelItems.length === 0) {
        alert('No tickets available for drawing.');
      }
    },
    onWheelEnd(winningItem) {
      this.isSpinning = false;
      if (winningItem) {
        const winningNumber = winningItem.htmlContent.match(/>(\d+)</)[1];
        this.spinResult = winningNumber;
        this.determineWinner(winningNumber);
        this.updateLastNumbers({
          number: winningNumber,
          color: winningItem.background
        });
      } else {
        console.error('Invalid winning item');
      }
    },
    async determineWinner(winningNumber) {
      try {
        const token = await getAccessToken();
        const response = await axios.get(`${config.apiBaseUrl}/api/getWinner`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            lotteryId: this.currentLotteryId,
            ticketNumber: winningNumber,
          },
        });
        this.winnerDetails = response.data;
        this.dialog = true;
      } catch (error) {
        console.error('Failed to fetch winner details:', error);
        alert('Error fetching winner details. Please try again.');
      }
    },
    async fetchTicketsForLottery() {
      if (!this.currentLotteryId) {
        console.error('No lottery ID available');
        return;
      }
      try {
        const token = await getAccessToken();
        const response = await axios.get(`${config.apiBaseUrl}/api/GetTicketsByLotteryId`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            lotteryId: this.currentLotteryId,
          },
        });
        this.tickets = response.data;
        this.generateWheelItems();
      } catch (error) {
        console.error('Failed to fetch tickets:', error.response ? error.response.data : error.message);
        this.tickets = [];
        this.wheelItems = [];
      }
    },
    generateWheelItems() {
      if (!this.tickets || this.tickets.length === 0) {
        console.warn('No tickets available to generate wheel items');
        this.wheelItems = [];
        return;
      }
      this.wheelItems = this.tickets.map((ticket, index) => ({
        id: index,
        htmlContent: `<span>${ticket.ticketNumber}</span>`,
        background: index % 2 === 0 ? 'red' : 'black',
        textColor: 'white'
      }));
      this.spinResult = null;
    },
    updateLastNumbers(number) {
      if (!this.lastNumbers.some(n => n.number === number.number)) {
        this.lastNumbers.unshift({ ...number, id: Date.now() });
        if (this.lastNumbers.length > 5) {
          this.lastNumbers.pop();
        }
      }
    }
  }
};
</script>

<style scoped>
.v-container {
  padding-top: 20px;
}
.v-dialog .headline {
  color: #2e7d32;
}
.v-card-title {
  font-weight: bold;
  font-size: 1.5em;
}
.v-card {
  margin-top: 20px;
}
.v-btn {
  margin-top: 15px;
}
.v-card-text p {
  margin-bottom: 10px;
}
.wheel-container {
  position: relative;
  width: 600px;
  margin: 20px auto;
}
.wheel-pointer {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid #333;
  z-index: 10;
}
#wheel_meta {
  text-align: center;
  margin-top: 20px;
}
.last-numbers {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.number-field {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  line-height: 40px;
  text-align: center;
  margin: 0 5px;
  font-weight: bold;
}
</style>
