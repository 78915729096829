import config from '@/config';

export const msalConfig = {
    auth: {
      clientId: 'de6ec972-b457-4cbb-a20f-b915a34a6c37',
      authority: 'https://login.microsoftonline.com/a6adec95-5680-40b1-83b3-a1ed7f2f6405',
      redirectUri: `${config.webBaseUrl}`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };  