import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

console.log(vuetify);

async function initializeMsal() {
    try {
      await msalInstance.initialize();
      console.log('MSAL initialized successfully');
    } catch (error) {
      console.error('Error initializing MSAL:', error);
    }
  }
initializeMsal().then(() => {
    const app = createApp(App);
    app.config.globalProperties.$msal = msalInstance;
    // Configure axios globally
    app.config.globalProperties.$http = axios;    


// Use Vuetify instance
app.use(vuetify);

// Use router
app.use(router);

// Mount the app to the DOM
app.mount('#app');
});