<template>
    <v-container>
      <h1>Login Test Page</h1>
      <v-btn @click="login" color="primary" class="mb-4">Log In</v-btn>
      <v-btn @click="logout" color="error" class="mb-4 ml-4">Log Out</v-btn>
      <v-btn @click="getToken" color="info" class="mb-4 ml-4">Get Token</v-btn>
      <v-btn @click="createLottery" color="success" class="mb-4 ml-4">Create Lottery</v-btn>
  
      <v-card v-if="userInfo" class="mt-4">
        <v-card-title>User Information</v-card-title>
        <v-card-text>
          <pre>{{ JSON.stringify(userInfo, null, 2) }}</pre>
        </v-card-text>
      </v-card>
  
      <v-card v-if="token" class="mt-4">
        <v-card-title>Access Token</v-card-title>
        <v-card-text>
          <pre>{{ token }}</pre>
        </v-card-text>
      </v-card>
  
      <v-card v-if="lotteryResult" class="mt-4">
        <v-card-title>Create Lottery Result</v-card-title>
        <v-card-text>
          <pre>{{ JSON.stringify(lotteryResult, null, 2) }}</pre>
        </v-card-text>
      </v-card>
  
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import { login, logout, getAccessToken } from './authService';
  import axios from 'axios';
  
  export default {
    name: 'LoginTest',
    data() {
      return {
        userInfo: null,
        token: null,
        lotteryResult: null,
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'info',
      };
    },
    methods: {
      async login() {
        try {
          await login();
          this.userInfo = await this.getUserInfo();
          this.showSnackbar('Logged in successfully', 'success');
        } catch (error) {
          console.error('Login failed:', error);
          this.showSnackbar('Login failed: ' + error.message, 'error');
        }
      },
      async logout() {
        try {
          await logout();
          this.userInfo = null;
          this.token = null;
          this.lotteryResult = null;
          this.showSnackbar('Logged out successfully', 'success');
        } catch (error) {
          console.error('Logout failed:', error);
          this.showSnackbar('Logout failed: ' + error.message, 'error');
        }
      },
      async getToken() {
        try {
          this.token = await getAccessToken();
          this.showSnackbar('Token retrieved', 'success');
        } catch (error) {
          console.error('Failed to get token:', error);
          this.showSnackbar('Failed to get token: ' + error.message, 'error');
        }
      },
      async getUserInfo() {
        const token = await getAccessToken();
        return JSON.parse(atob(token.split('.')[1]));
      },
      showSnackbar(text, color) {
        this.snackbarText = text;
        this.snackbarColor = color;
        this.snackbar = true;
      },
      async createLottery() {
        try {
          const token = await getAccessToken();
          console.log('Token before API call:', token); // Add this line

          const response = await axios.post(
            'http://localhost:7071/api/createLottery',
        {
        lotteryDate: new Date().toISOString(),
        theme: 'Test Lottery'
        },
        { 
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
    );
    console.log('Axios request config:', response.config); // Log the entire request configuration
    console.log('Lottery created:', response.data);
          this.lotteryResult = response.data;
          this.showSnackbar('Lottery created successfully', 'success');
        } catch (error) {
          console.error('Failed to create lottery:', error);
          this.showSnackbar('Failed to create lottery: ' + (error.response?.data?.error || error.message), 'error');
        }
      }
    },
  };
  </script>
  