// src/services/ticketUtils.js

import axios from 'axios';
import config from '@/config';
import { getAccessToken } from '../components/authService';

export async function fetchTicketsForUser(userId) {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${config.apiBaseUrl}/api/getTickets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userID: userId,
      },
    });
    return response.data.tickets;
  } catch (error) {
    console.error('Error fetching tickets for user:', error);
    throw error;
  }
}

export async function fetchTicketsForLottery(lotteryId) {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${config.apiBaseUrl}/api/GetTicketsByLotteryId`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        lotteryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch tickets:', error);
    throw error;
  }
}

export async function fetchAllLotteries() {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${config.apiBaseUrl}/api/GetAllLotteries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch lotteries:', error);
    throw error;
  }
}

export function findNewestLottery(lotteries) {
  return lotteries.reduce((newest, lottery) => {
    const lotteryDate = new Date(lottery.date);
    const newestDate = new Date(newest.date);

    if (isNaN(lotteryDate.getTime()) || isNaN(newestDate.getTime())) {
      console.error('Invalid date encountered:', lottery.date, newest.date);
      return newest;
    }

    return lotteryDate > newestDate ? lottery : newest;
  }, lotteries[0]);
}

export async function fetchTicketsForUserAndLottery(userId, lotteryId) {
  try {
    const allUserTickets = await fetchTicketsForUser(userId);
    return allUserTickets.filter(ticket => ticket.lotteryId === lotteryId);
  } catch (error) {
    console.error('Error fetching tickets for user and lottery:', error);
    throw error;
  }
}

export async function fetchNewestLottery() {
  try {
    const lotteries = await fetchAllLotteries();
    return findNewestLottery(lotteries);
  } catch (error) {
    console.error('Failed to fetch newest lottery:', error);
    throw error;
  }
}
