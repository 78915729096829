<template>
  <v-app>
    <v-app-bar app light color="primary">
      <v-toolbar-title>Gture Vinlotteri</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/" class="app-bar-btn">Hjem</v-btn>
      <v-btn text to="/buy-specific-number" v-if="isLoggedIn">Kjøp Lodd</v-btn>
      <v-btn text to="/AdminPage" class="app-bar-btn" v-if="isLoggedIn">Admin</v-btn>
      <v-btn text to="/spinningwheel" v-if="isLoggedIn">Spinningwheel</v-btn>
      <v-btn text to="/login" color="red" v-if="!isLoggedIn">Login</v-btn>
      <v-btn text to="/login" v-if="isLoggedIn">Profile</v-btn>
    </v-app-bar>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000" bottom right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>

    <v-main>
      <v-container>
        <router-view @show-snackbar="showSnackbar"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { isAuthenticated, logout as authServiceLogout } from '@/components/authService'

export default {
  name: 'App',
  data() {
    return {
      snackbar: {
        show: false,
        message: '',
        color: ''
      },
      isLoggedIn: false
    };
  },
  methods: {
    showSnackbar(message, color) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = color;
    },
    async checkAuthStatus() {
      this.isLoggedIn = await isAuthenticated();
    },
    async logout() {
      try {
        await authServiceLogout();
        this.isLoggedIn = false;
        this.$router.push('/login');
        this.showSnackbar('Logged out successfully', 'success');
      } catch (error) {
        console.error('Logout failed:', error);
        this.showSnackbar('Logout failed', 'error');
      }
    }
  },
  async created() {
    await this.checkAuthStatus();
    this.$watch(
      () => this.$route,
      async () => {
        await this.checkAuthStatus();
      }
    );
  },
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.app-bar-btn:hover {
  background-color: rgba(255, 152, 0, 0.2);
}

.v-toolbar-title {
  font-weight: bold;
}

.router-link-active {
  text-decoration: underline;
  color: #F26622;
}
</style>
