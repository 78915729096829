<template>
  <v-container>
    <v-row>
      <!-- Left Space for Administrator Information and Actions -->
      <v-col cols="12" md="8">
        <v-container>
          <h1>Administratorweb</h1>
          <p>Her kan du administrere Gture sine vinlotterier.</p>
          <v-btn color="primary" class="mr-2" @click="openCreateVinlotteriDialog">Opprett nytt vinlotteri</v-btn>
          <v-btn color="secondary" @click="startTrekning">Start trekning</v-btn>
        </v-container>
      </v-col>

      <!-- Right Space for Vinlotterier List -->
      <v-col cols="12" md="4">
        <v-container>
          <h2>Alle vinlotterier</h2>
          <v-list dense class="rounded-list">
            <v-list-item-group>
              <router-link
                v-for="(item, index) in items"
                :key="index"
                :to="{ name: 'LotteryDetails', params: { id: item._id } }"
                v-slot="{ href, navigate }"
              >
                <v-list-item
                  :href="href"
                  :link="true"
                  @click="navigate"
                  :class="{ 'last-item': index === items.length - 1 }"
                >
                  <v-list-item-title class="title-text">{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle class="subtitle-text">{{ item.subtitle }}</v-list-item-subtitle>
                  <template v-slot:append>
                    <v-chip v-if="index === 0" color="success" size="small" class="mr-2">
                      Aktivt
                    </v-chip>
                  </template>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-col>
    </v-row>

    <!-- Create Vinlotteri Dialog -->
    <v-dialog v-model="createVinlotteriDialog" max-width="500px">
      <v-card>
        <v-card-title>Opprett nytt vinlotteri</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="newVinlotteri.theme" label="Tema"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newVinlotteri.lotteryDate"
                label="Dato"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createVinlotteri">Opprett</v-btn>
          <v-btn color="secondary" @click="closeCreateVinlotteriDialog">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for Notifications -->
    <v-snackbar v-model="snackbar.visible" :timeout="3000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'; 
import { getAccessToken } from './authService';
import config from '@/config';

export default defineComponent({
  name: 'AdminPage',
  setup() {
    const router = useRouter(); 
    const items = ref([]);
    const createVinlotteriDialog = ref(false);
    const snackbar = ref({
      visible: false,
      message: '',
    });

    const newVinlotteri = ref({
      theme: '',
      lotteryDate: new Date().toISOString().substr(0, 10), 
    });

    const checkIfAdmin = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/checkIfAdmin`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (!result.isAdmin) {
          // Show Snackbar message
          snackbar.value.message = 'Bare admins kan komme hit, ikke prøv igjen';
          snackbar.value.visible = true;

          // Redirect to home page after showing snackbar
          setTimeout(() => {
            router.push({ name: 'HomeVinlotteri' });
          }, 2000);
          return false;
        }
        return true;
      } catch (err) {
        console.error(err);

        // Show Snackbar message
        snackbar.value.message = 'Bare admins kan komme hit, ikke prøv igjen';
        snackbar.value.visible = true;

        // Redirect to home page after showing snackbar
        setTimeout(() => {
          router.push({ name: 'HomeVinlotteri' });
        }, 2000);
        return false;
      }
    };

    const startTrekning = () => {
      router.push('/SpinningWheel');
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date format: ${dateString}`);
        return dateString;
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const openCreateVinlotteriDialog = () => {
      createVinlotteriDialog.value = true;
    };

    const closeCreateVinlotteriDialog = () => {
      createVinlotteriDialog.value = false;
      resetNewVinlotteri();
    };

    const createVinlotteri = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/createLottery`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            lotteryDate: newVinlotteri.value.lotteryDate,
            theme: newVinlotteri.value.theme,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Nytt vinlotteri opprettet:', result);
        resetNewVinlotteri();
        closeCreateVinlotteriDialog();
        fetchVinlotterier();
      } catch (error) {
        console.error('Feil ved oppretting av vinlotteri:', error);
      }
    };

    const resetNewVinlotteri = () => {
      newVinlotteri.value = {
        theme: '',
        lotteryDate: new Date().toISOString().split('T')[0],
      };
    };

    const fetchVinlotterier = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${config.apiBaseUrl}/api/GetAllLotteries`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        localStorage.setItem('allLotteries', JSON.stringify(sortedData)); // Store to localStorage

        items.value = sortedData.map((item) => ({
          _id: item._id,
          title: item.theme,
          subtitle: `Dato: ${formatDate(item.date)}`,
          date: item.date,
          createdDate: item.createdDate,
          wines: item.wines || [],
        }));
      } catch (error) {
        console.error('Det skjedde en feil i uthenting av vinlotterier:', error);
      }
    };

    onMounted(async () => {
      const isAdmin = await checkIfAdmin();
      if (isAdmin) {
        fetchVinlotterier();
      }
    });

    return {
      items,
      createVinlotteriDialog,
      newVinlotteri,
      openCreateVinlotteriDialog,
      closeCreateVinlotteriDialog,
      createVinlotteri,
      resetNewVinlotteri,
      fetchVinlotterier,
      startTrekning,
      snackbar, // Add snackbar state to the return object
    };
  },
});
</script>

<style>
.rounded-list {
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb
  (var(--v-theme-surface));
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.title-text {
  font-weight: bold;
}

.subtitle-text {
  opacity: 0.8;
}

.v-chip {
  font-size: 0.75rem;
  height: 20px;
}
</style>
