<template>
  <v-app>
    <v-container>
      <h1>Velkommen til Gture sitt vinlotteri!</h1>

      <v-row>
        <v-col
          v-for="(box, index) in boxes"
          :key="index"
          cols="12"
          md="4"
        >
          <router-link
            v-if="box.internal"
            :to="box.link"
            class="box-link"
            exact
          >
            <v-card class="mx-auto" max-width="344" color="secondary" dark>
              <v-card-text>
                <p>{{ box.text }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary">Til {{ box.title }}</v-btn>
              </v-card-actions>
            </v-card>
          </router-link>
          <a
            v-else
            :href="box.link"
            target="_blank"
            rel="noopener"
            class="box-link"
          >
            <v-card class="mx-auto" max-width="344" color="secondary" dark>
              <v-card-text>
                <p>{{ box.text }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary">Til {{ box.title }}</v-btn>
              </v-card-actions>
            </v-card>
          </a>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="index"
          cols="12"
          md="4"
        >
          <v-img
            :src="require(`@/assets/${image}`)"
            alt="Wine Image"
            aspect-ratio="16/9"
            class="my-image scaled-my-image"
          ></v-img>
        </v-col>
      </v-row>

      <hr />

      <h2 class="sub-header">MØT TEAMET</h2>

      <v-img
        :src="require('@/assets/gturegang.png')"
        alt="Header Image"
        aspect-ratio="16/9"
        class="header-image scaled-header-image"
      ></v-img>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      boxes: [
        {
          title: 'Vinlotteri',
          text: 'Jobber du i Gture? Klikk linken under for å komme til verdens beste vinlotteri!',
          link: '/login',
          internal: true,
        },
        {
          title: 'Søknad',
          text: 'Jobber du ikke i Gture, men synes dette ser kult ut? Klikk linken under for å sende oss en søknad!',
          link: 'https://gture.appfarm.app/rekruttering',
          internal: false,
        },
        {
          title: 'Gture.ai',
          text: 'Trenger dere en chatbot? Klikk linken under for å få et uforpliktende tilbud på gratis AI workshop!',
          link: 'https://www.gture.ai/',
          internal: false,
        },
      ],
      images: ['wine.png', 'jobbsok.png', 'gtureai.png'],
    };
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  margin-top: 5px;
}
.sub-header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.header-image {
  margin-bottom: 5px;
}
.my-image {
  max-width: 100%;
  height: auto;
}
.scaled-header-image {
  transform: scale(0.8); /* Adjust the scale factor as needed */
  margin: 0 auto; /* Center the image if necessary */
}
.scaled-my-image {
  transform: scale(0.6); /* Adjust the scale factor as needed */
  margin: 0 auto; /* Center the image if necessary */
}
.box-link {
  text-decoration: none;
  display: block;
}
.box-link .v-card {
  transition: border 0.3s ease;
}
.box-link:hover .v-card {
  border: 2px solid var(--v-primary-base); /* Using Vuetify CSS variable for primary color */
}
</style>
