<template>
  <v-container>
    <h1>Kjøp spesifikt lodd for det nyeste lotteriet - temaet er: {{ latestLottery ? latestLottery.theme : 'Ingen aktive lotterier' }}</h1>
    
    <div class="number-grid">
      <div v-for="group in groupedNumbers" :key="group.start" class="number-group">
        <div class="group-label">{{ group.start }}-{{ group.end }}</div>
        <div class="group-numbers">
          <div
            v-for="number in group.numbers"
            :key="number"
            class="number-cell"
            :class="{
              'selected': selectedNumbers.includes(number),
              'unavailable': unavailableNumbers.includes(number),
              'user-owned': userOwnedNumbers.includes(number)
            }"
            @click="toggleNumberSelection(number)"
          >
            <div class="content-wrapper">
              <img
                v-if="unavailableNumbers.includes(number) && userImages[ticketOwners[number]]"
                :src="userImages[ticketOwners[number]]"
                class="user-image"
                :alt="ticketOwners[number]"
              />
              <span class="number-label">{{ number }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="quick-pick-section">
      <h2>Kjøp tilfeldige lodd</h2>
      <div class="quick-pick-controls">
        <v-btn @click="decreaseQuickPickCount" icon><v-icon>mdi-minus</v-icon></v-btn>
        <span class="quick-pick-count">{{ quickPickCount }}</span>
        <v-btn @click="increaseQuickPickCount" icon><v-icon>mdi-plus</v-icon></v-btn>
      </div>
      <v-btn @click="buyRandomNumbers" color="primary" :disabled="quickPickCount === 0">
        Kjøp {{ quickPickCount }} tilfeldige lodd
      </v-btn>
    </div>

    <v-btn large color="success" class="buy-button" @click="buySelectedNumbers" :disabled="selectedNumbers.length === 0">
      Kjøp {{ selectedNumbers.length }} valgte nummer
    </v-btn>
  </v-container>
</template>

<script>
import axios from 'axios';
import { getAccessToken, getUserInfo } from './authService';
import config from '@/config';
import { fetchNewestLottery, fetchTicketsForUserAndLottery } from '@/services/ticketUtils';
import { getStoredData, setStoredData } from '@/utils/storageUtils';

export default {
  name: 'BuySpecificLotteryNumber',
  data() {
    return {
      lotteryNumbers: Array.from({ length: 75 }, (_, i) => i + 1),
      selectedNumbers: [],
      unavailableNumbers: [],
      userOwnedNumbers: [],
      userId: null,
      ticketOwners: {},
      userImages: {
        'adne': require('@/assets/userImages/adne.png'),
        'user123': require('@/assets/userImages/knut.png'),
      },
      latestLottery: null,
      quickPickCount: 0,
    };
  },
  computed: {
    groupedNumbers() {
      const groups = [];
      for (let i = 1; i <= this.lotteryNumbers.length; i += 15) {
        groups.push({
          start: i,
          end: Math.min(i + 14, this.lotteryNumbers.length),
          numbers: this.lotteryNumbers.slice(i - 1, i + 14)
        });
      }
      return groups;
    },
  },
  methods: {
    toggleNumberSelection(number) {
      if (this.unavailableNumbers.includes(number) || this.userOwnedNumbers.includes(number)) {
        return;
      }
      const index = this.selectedNumbers.indexOf(number);
      if (index > -1) {
        this.selectedNumbers.splice(index, 1);
      } else {
        this.selectedNumbers.push(number);
      }
    },
    async buySelectedNumbers() {
      if (!this.userId || !this.latestLottery) {
        this.$emit('show-snackbar', 'Manglende brukerdata eller lotteri-informasjon.', 'error');
        return;
      }

      if (this.selectedNumbers.length === 0) {
        this.$emit('show-snackbar', 'Ingen nummer valgt', 'error');
        return;
      }
      
      try {
        const token = await getAccessToken();
        const postData = {
          userId: this.userId,
          ticketNumbers: this.selectedNumbers,
          lotteryId: this.latestLottery._id
        };
        
        const response = await axios.post(`${config.apiBaseUrl}/api/BuyTickets`, postData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        this.handleSuccess(response.data);
        
        this.userOwnedNumbers.push(...this.selectedNumbers);
        setStoredData(`tickets_${this.latestLottery._id}_${this.userId}`, this.userOwnedNumbers);
        
        this.unavailableNumbers.push(...this.selectedNumbers);
        this.selectedNumbers = [];
        await this.fetchTicketsForLottery();
      } catch (error) {
        this.handleError(error);
      }
    },
    handleSuccess() {
      this.$emit('show-snackbar', 'Suksess', 'success');
    },
    handleError(error) {
      this.$emit('show-snackbar', `Feil: ${error.message}`, 'error');
    },
    async fetchTicketsForLottery() {
      console.log("Fetching tickets for lottery:", this.latestLottery._id);
      console.log("User ID:", this.userId);
      if (!this.latestLottery || !this.userId) {
        console.error("No active lottery found or user ID not set");
        return;
      }

      const storedTickets = getStoredData(`tickets_${this.latestLottery._id}_${this.userId}`);
      if (storedTickets) {
        console.log("Using stored tickets");
        this.userOwnedNumbers = storedTickets;
      }

      try {
        const userTickets = await fetchTicketsForUserAndLottery(this.userId, this.latestLottery._id);
        this.userOwnedNumbers = userTickets.map(ticket => ticket.ticketNumber);
        console.log("User tickets:", userTickets);
        console.log("User owned numbers:", this.userOwnedNumbers);
        
        setStoredData(`tickets_${this.latestLottery._id}_${this.userId}`, this.userOwnedNumbers);

        const token = await getAccessToken();
        const response = await axios.get(`${config.apiBaseUrl}/api/GetTicketsByLotteryId?lotteryId=${this.latestLottery._id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.unavailableNumbers = response.data
          .map(ticket => ticket.ticketNumber)
          .filter(num => !this.userOwnedNumbers.includes(num));
      } catch (error) {
        console.error("Failed to fetch tickets:", error);
        this.unavailableNumbers = [];
        this.userOwnedNumbers = [];
      }
    },
    async getUserInfo() {
      try {
        const userInfo = await getUserInfo();
        this.userId = userInfo.userId;
        console.log("User ID set:", this.userId);
      } catch (error) {
        console.error("Failed to get user info:", error);
        this.$emit('show-snackbar', 'Kunne ikke hente brukerdata.', 'error');
      }
    },
    async fetchLatestLottery() {
      try {
        this.latestLottery = await fetchNewestLottery();
        if (!this.latestLottery) {
          throw new Error('No active lottery found');
        }
      } catch (error) {
        console.error('Failed to fetch latest lottery:', error);
        this.$emit('show-snackbar', 'Kunne ikke hente siste lotteri.', 'error');
      }
    },
    increaseQuickPickCount() {
      if (this.quickPickCount < 10) {
        this.quickPickCount++;
      }
    },
    decreaseQuickPickCount() {
      if (this.quickPickCount > 0) {
        this.quickPickCount--;
      }
    },
    async buyRandomNumbers() {
      if (!this.userId || !this.latestLottery) {
        this.$emit('show-snackbar', 'Manglende brukerdata eller lotteri-informasjon.', 'error');
        return;
      }

      const availableNumbers = this.lotteryNumbers.filter(num => 
        !this.unavailableNumbers.includes(num) && !this.userOwnedNumbers.includes(num)
      );
      if (availableNumbers.length < this.quickPickCount) {
        this.$emit('show-snackbar', 'Ikke nok tilgjengelige numre.', 'error');
        return;
      }

      const randomNumbers = [];
      while (randomNumbers.length < this.quickPickCount) {
        const randomIndex = Math.floor(Math.random() * availableNumbers.length);
        randomNumbers.push(availableNumbers.splice(randomIndex, 1)[0]);
      }

      try {
        const token = await getAccessToken();
        const postData = {
          userId: this.userId,
          ticketNumbers: randomNumbers,
          lotteryId: this.latestLottery._id
        };
        
        const response = await axios.post(`${config.apiBaseUrl}/api/BuyTickets`, postData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        this.handleSuccess(response.data);
        this.userOwnedNumbers.push(...randomNumbers);
        setStoredData(`tickets_${this.latestLottery._id}_${this.userId}`, this.userOwnedNumbers);
        this.unavailableNumbers.push(...randomNumbers);
        this.quickPickCount = 0;
        await this.fetchTicketsForLottery();
      } catch (error) {
        this.handleError(error);
      }
    },
    startBackgroundSync() {
      setInterval(() => {
        this.fetchTicketsForLottery();
      }, 60000); // Sync every minute
    },
  },
  async mounted() {
    await this.fetchLatestLottery();
    if (this.latestLottery) {
      await this.getUserInfo();
      await this.fetchTicketsForLottery();
      this.startBackgroundSync();
    } else {
      this.$emit('show-snackbar', 'Ingen aktive lotterier funnet.', 'warning');
    }
  }
};
</script>

<style>
.number-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.number-group {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
}
.group-label {
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}
.group-numbers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}
.number-cell {
  padding: 12px;
  background-color: #ffffff;
  border: 2px solid #dddddd;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: relative;
}
.number-cell:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}
.number-cell.selected {
  background-color: #76c7c0;
  color: white;
  border-color: #459a89;
}
.number-cell.unavailable {
  background-color: #cccccc;
  cursor: not-allowed;
}
.number-cell.user-owned {
  background-color: #4CAF50;
  color: white;
  border-color: #45a049;
  cursor: not-allowed;
}
.content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.user-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.9;
}
.number-label {
  position: relative;
  z-index: 2;
  font-size: 1.2em;
  font-weight: bold;
  color: black;
}
.buy-button {
  margin-top: 30px;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}
.buy-button:hover {
  background-color: #68a9a0;
  box-shadow: 0 6px 12px rgba(0,0,0,0.25);
}
.quick-pick-section {
  margin-top: 30px;
  text-align: center;
}
.quick-pick-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.quick-pick-count {
  font-size: 24px;
  margin: 0 20px;
}
</style>
