<template>
  <v-container>
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-card-title class="headline primary white--text">
            Velkommen til Gture Vinlotteri
          </v-card-title>
          
          <v-card-text v-if="!isUserLoggedIn" class="mt-4">
            <p class="subtitle-1">
              Er du glad i vin og føler deg heldig? Da er du på rett sted!
            </p>
            <p class="body-1 mt-2">
              Gture Vinlotteri gir deg muligheten til å vinne utsøkte viner samtidig som du støtter en god sak. 
              For å delta i vårt spennende vinlotteri, vennligst logg inn eller opprett en konto.
            </p>
            <v-alert
              class="mt-4"
              type="info"
              outlined
            >
              <strong>Slik fungerer det:</strong>
              <ol class="mt-2">
                <li>Logg inn på kontoen din</li>
                <li>Kjøp loddene dine</li>
                <li>Vent på den spennende trekningen</li>
                <li>Vinn fantastiske viner!</li>
              </ol>
            </v-alert>
          </v-card-text>

          <v-card-text v-else class="mt-4">
            <p class="subtitle-1">Velkommen, {{ userInfo.name }}!</p>
            <p class="body-1 mt-2">Du er nå logget inn og klar til å delta i vinlotteriet!</p>
            <!-- You can add more content here for logged-in users if needed -->
            <v-divider class="my-4"></v-divider>
  
            <h3 class="headline mb-3">Dine lodd for lotteriet: {{ newestLottery?.theme || newestLottery?._id }}</h3>
            <v-list v-if="tickets.length > 0">
            <v-list-item v-for="ticket in tickets" :key="ticket.id">
           <v-list-item-content>
             <v-list-item-title>Lodd #{{ ticket.ticketNumber }}</v-list-item-title>
               <v-list-item-subtitle>Kjøpt: {{ new Date(ticket.purchaseDate).toLocaleDateString() }}</v-list-item-subtitle>
             </v-list-item-content>
          </v-list-item>
            </v-list>
           <p v-else>Du har ingen lodd for øyeblikket.</p>
         
          </v-card-text>


          <v-card-actions class="justify-center pb-4">
            <v-btn
              color="primary"
              x-large
              @click="performLogin"
              v-if="!isUserLoggedIn"
            >
              Logg inn for å delta
            </v-btn>
            <div v-if="isUserLoggedIn" class="text-center">
              <p class="success-message">Logget inn som: {{ userInfo.username }}</p>
              <v-btn color="error" @click="performLogout">Logg ut</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar for login feedback -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Lukk
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { login as authServiceLogin, isAuthenticated, logout as authServiceLogout, getUserInfo } from './authService';
import { fetchTicketsForUserAndLottery, fetchNewestLottery } from '@/services/ticketUtils';
import { getStoredData, setStoredData, removeStoredData } from '@/utils/storageUtils';

export default {
  data() {
    return {
      loginState: false,
      userInfo: {
        name: '',
        username: '',
        userId: ''
      },
      snackbar: {
        show: false,
        message: '',
        color: ''
      },
      tickets: [],
      newestLottery: null,
    };
  },
  computed: {
    isUserLoggedIn() {
      return this.loginState;
    }
  },
  async created() {
    await this.checkLoginStatus();
  },
  methods: {
    async checkLoginStatus() {
      this.loginState = await isAuthenticated();
      if (this.loginState) {
        const storedUserInfo = getStoredData('userInfo');
        if (storedUserInfo) {
          this.userInfo = storedUserInfo;
        } else {
          const userInfo = await getUserInfo();
          this.userInfo = userInfo || { name: 'Bruker', username: 'Ukjent bruker' };
          setStoredData('userInfo', this.userInfo);
        }
        await this.fetchTickets();
      } else {
        this.userInfo = { name: '', username: '', userId: '' };
        removeStoredData('userInfo');
        removeStoredData('tickets');
      }
    },
    async performLogin() {
      try {
        const loginSuccess = await authServiceLogin();
        if (loginSuccess) {
          await this.checkLoginStatus();
          this.showSnackbar('Innlogging vellykket!', 'success');
        } else {
          this.showSnackbar('Innlogging mislyktes. Vennligst prøv igjen.', 'error');
        }
      } catch (error) {
        console.error('Innlogging mislyktes:', error);
        this.showSnackbar('Innlogging mislyktes. Vennligst prøv igjen.', 'error');
      }
    },
    async performLogout() {
      try {
        await authServiceLogout();
        this.loginState = false;
        this.userInfo = { name: '', username: '', userId: '' };
        this.tickets = [];
        removeStoredData('userInfo');
        removeStoredData('tickets');
        this.showSnackbar('Utlogging vellykket!', 'success');
        this.$router.push('/');
      } catch (error) {
        console.error('Utlogging mislyktes:', error);
        this.showSnackbar('Utlogging mislyktes. Vennligst prøv igjen.', 'error');
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    async fetchTickets() {
      try {
        if (this.userInfo && this.userInfo.userId) {
          console.log("userid: ", this.userInfo.userId);
          
          const storedTickets = getStoredData('tickets');
          if (storedTickets) {
            this.tickets = storedTickets;
            this.newestLottery = getStoredData('newestLottery');
            console.log('Using stored tickets:', this.tickets);
          }

          this.newestLottery = await fetchNewestLottery();
          setStoredData('newestLottery', this.newestLottery);

          if (this.newestLottery) {
            let fetchedTickets = await fetchTicketsForUserAndLottery(this.userInfo.userId, this.newestLottery._id);
            
            // Sort the tickets in ascending order based on ticketNumber
            this.tickets = fetchedTickets.sort((a, b) => a.ticketNumber - b.ticketNumber);
            
            setStoredData('tickets', this.tickets);
            
            console.log('Fetched and sorted tickets:', this.tickets); // Debug log
          } else {
            console.log('No active lottery found');
            this.tickets = [];
            removeStoredData('tickets');
          }
        } else {
          console.error('User ID not available');
          this.showSnackbar('Unable to fetch tickets: User ID not available', 'error');
        }
      } catch (error) {
        console.error('Failed to fetch tickets:', error);
        this.showSnackbar('Failed to fetch tickets. Please try again.', 'error');
      }
    },
  },
}
</script>

<style scoped>
.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}
</style>