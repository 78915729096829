<template>
  <v-container v-if="lottery">
    <h1>Vinlotteri Detaljer</h1>
    <v-row>
      <v-col cols="12" md="6">
        <p><strong>Tema:</strong> {{ lottery.title }}</p>
        <p><strong>Dato:</strong> {{ formatDate(lottery.date) }}</p>
      </v-col>
    </v-row>
  
    <h2>Wines</h2>
  
    <!-- Wine List Table -->
    <v-simple-table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="wine in lottery.wines" :key="wine._id">
          <td>{{ wine.name }}</td>
          <td>{{ wine.description }}</td>
          <!-- Add any action buttons for each wine here -->
        </tr>
        <tr>
          <td>
            <v-text-field v-model="newWine.name" label="Name" outlined dense></v-text-field>
          </td>
          <td>
            <v-text-field v-model="newWine.description" label="Description" outlined dense></v-text-field>
          </td>
          <td>
            <v-btn color="primary" @click="addWine">Add</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
      
    <v-btn color="primary" @click="submitWines" class="mt-4">Submit All Wines</v-btn>
  
    <!-- Add this new button -->
    <v-btn color="success" @click="goToSpinningWheel" class="mt-4 ml-4">Start Lottery</v-btn>
    <!-- Snackbar for feedback -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
  <v-container v-else>
    <h2>Loading lottery details...</h2>
  </v-container>
</template>
  
<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'; // Add useRouter here
import { getAccessToken } from './authService';
import config from '@/config';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter(); // Add this line
    const lottery = ref(null);
    const newWine = ref({ name: '', description: '' });
    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('info');
  
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date format: ${dateString}`);
        return dateString;
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };
  
    const fetchLotteryDetails = () => {
      const stateLottery = route.state?.lottery;
      if (stateLottery) {
        lottery.value = stateLottery;
      } else {
        const allLotteries = JSON.parse(localStorage.getItem('allLotteries')) || [];
        const lotteryId = route.params.id;
        const foundLottery = allLotteries.find((l) => l._id === lotteryId);
        if (foundLottery) {
          lottery.value = foundLottery;
        } else {
          console.warn('Lottery details are missing.');
        }
      }
    };
  
    const addWine = () => {
      if (newWine.value.name && newWine.value.description) {
        lottery.value.wines.push({ ...newWine.value });
        newWine.value = { name: '', description: '' };
      } else {
        showSnackbar('Please fill in both name and description.', 'error');
      }
    };
  
    const submitWines = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(
          `${config.apiBaseUrl}/api/updateLottery/${lottery.value._id}`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ wines: lottery.value.wines }),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const updatedLottery = await response.json();
        lottery.value = updatedLottery;
        showSnackbar('Wines submitted successfully!', 'success');
      } catch (error) {
        showSnackbar('Failed to submit wines.', 'error');
      }
    };

    const showSnackbar = (text, color) => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    const goToSpinningWheel = () => {
      router.push({ 
        name: 'SpinningWheel', 
        params: { id: lottery.value._id }
      });
    };

    onMounted(fetchLotteryDetails);

    return {
      lottery,
      formatDate,
      newWine,
      addWine,
      submitWines,
      snackbar,
      snackbarText,
      snackbarColor,
      goToSpinningWheel, // Add this to the returned object
    };
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}

h2 {
  margin-top: 30px;
}

v-simple-table {
  margin-top: 20px;
}

v-text-field {
  width: 100%;
}

v-btn {
  margin-right: 10px;
  margin-bottom: 10px;
}

.mt-4 {
  margin-top: 20px;
}
</style>
