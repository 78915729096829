// src/plugins/vuetify.js
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'light', // Specify the default theme to be used (optional if you only have a light theme)
        themes: {
            light: {
                colors: {
                    primary: '#090446', // Dark Blue
                    secondary: '#F26622', // Orange
                    // Add other colors and customize as needed
                },
            },
            // Define other themes such as 'dark' if needed
        },
    },
})
